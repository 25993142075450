<template>
  <div class="footer">
    <p class="footer-text">
      {{footer}}
      <a href="https://www.facebook.com/MinhPhuong.VN18/" target="_blank" class="footer-link">
        <b>MP.</b>
      </a>
       {{updatedAt}} 20/12/2024
    </p>
  </div>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    currentLanguage() {
      return this.$store.state.currentLanguage;
    },

    footer() {
      return this.currentLanguage.footer;
    },
    
    updatedAt() {
      return this.currentLanguage.updatedAt;
    }
  },
};
</script>

<style scoped>
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-text {
  text-align: center;
}

.footer-link {
  color: #007f96;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}
</style>
